/******************************************************************************\
* File: Bookmark.jsx
*
* Author: Gigster
*
* Description: Toggleable Bookmark Icon
*
* Notes: Can be used as controlled or uncontrolled component
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import Bookmark_ from '@/components/common/icons/Bookmark';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Bookmark extends React.Component {
    static defaultProps = {
        onToggle: () => {},
        initialValue: false
    };

    constructor(props) {
        super(props);
        this.state = {
            active: props.initialValue || false
        };
    }

    isActive = () =>
        typeof this.props.active !== 'undefined'
            ? this.props.active
            : this.state.active;

    handleToggle = (e) => {
        const active = !this.isActive();
        this.setState({ active });
        this.props.onToggle && this.props.onToggle(active);
        e.stopPropagation();
        e.preventDefault();
    };

    render() {
        const { className, disabled, active } = this.props;
        return (
            <Bookmark_
                className={classNames(className, {
                    inactiveClassName: !!disabled
                })}
                onClick={this.handleToggle}
                active={active}
            />
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Bookmark;
