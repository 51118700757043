/******************************************************************************\
* File: Select.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/cards/Select.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Angle from '@/components/common/icons/Angle';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { clickAway } from '@/helpers/hoc';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Select extends React.Component {
    state = {
        show: false
    };

    onClickAway = () => {
        const { show } = this.state;

        if (show) {
            this.setState({ show: false });
        }
    };

    getFilteredOptions = () => {
        const { selected, options, onSelect } = this.props;

        return options.filter((option) => option.key !== selected.key);
    };

    onSelect = (option) => {
        const { onSelect } = this.props;

        onSelect(option);
        this.setState({ show: false });
    };

    render() {
        const { className, selected, onSelect, hasId = false } = this.props;

        const { show } = this.state;

        const cn = classNames(style.Select, {
            [className]: className
        });

        const options = this.getFilteredOptions();
        const id = hasId
            ? `${('label-' + selected.label).toLowerCase()}`
            : undefined;
        const dataId = hasId
            ? `${('label-' + selected.label).toLowerCase()}`
            : undefined;
        return (
            <div className={cn}>
                <div
                    className={style.label}
                    onClick={() => this.setState({ show: !show })}
                    id={id}
                    data-testid={dataId}>
                    {selected.label}
                    <Angle
                        className={style.angle}
                        direction={show ? 'up' : 'down'}
                    />
                </div>
                <div
                    className={classNames(style.dropdown, {
                        [style['show']]: show
                    })}>
                    <div className={style.wrapper}>
                        <svg className={style.triangle} viewBox="0 0 16 8">
                            <polygon points="0,8 8,0 16,8" />
                        </svg>
                    </div>
                    <div className={style.list}>
                        {options.map((option) => (
                            <div
                                className={style.item}
                                onClick={() => this.onSelect(option)}
                                key={option.key}
                                id={hasId ? `${option.key}` : undefined}
                                data-testid={
                                    hasId ? `${option.key}` : undefined
                                }>
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(Select);
